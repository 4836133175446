<template>
  <!-- div id="wrap" :class="{confirmp: name === 'ConfirmPassword'}" -->
  <div id="wrap">
    <div
      :id="wrapId"
      :class="{
        member:
          name === 'Login' ||
          name === 'Find_Id_Pwd' ||
          name === 'ConfirmPassword' ||
          name === 'Terms' ||
          name === 'Privacy',
        championship: name === 'TournamentSchedule',
        guide: name === 'JoinTournament',
        minigame: name === 'JoinMiniGame',
        terms: name === 'Terms',
        privacy: name === 'Privacy',
      }"
    >
      <Header />
      <slot />
    </div>
    <Footer />
    <loading
      :active.sync="$store.state.axiosstatus"
      :can-cancel="false"
      transition="fade"
      loader="dots"
      :is-full-page="true"
    ></loading>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "DefaultLayout",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    wrapId() {
      return this.$route.meta.wrapId || "sub";
    },
    name: function () {
      return this.$route.name ? this.$route.name : "";
    },
  },
};
</script>
