var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"wrap"}},[_c('div',{class:{
      member:
        _vm.name === 'Login' ||
        _vm.name === 'Find_Id_Pwd' ||
        _vm.name === 'ConfirmPassword' ||
        _vm.name === 'Terms' ||
        _vm.name === 'Privacy',
      championship: _vm.name === 'TournamentSchedule',
      guide: _vm.name === 'JoinTournament',
      minigame: _vm.name === 'JoinMiniGame',
      terms: _vm.name === 'Terms',
      privacy: _vm.name === 'Privacy',
    },attrs:{"id":_vm.wrapId}},[_c('Header'),_vm._t("default")],2),_c('Footer'),_c('loading',{attrs:{"active":_vm.$store.state.axiosstatus,"can-cancel":false,"transition":"fade","loader":"dots","is-full-page":true},on:{"update:active":function($event){return _vm.$set(_vm.$store.state, "axiosstatus", $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }